<template>
  <v-container grid-list-xl>
    <v-card-text>
      <v-row>
        <v-col
          v-for="project in projects"
          :key="project.id"
          cols="12"
          sm="6"
          md="4"
          lg="3"
        >
          <v-card
            outlined
            min-height="10em"
            height="100%"
            :to="{ name: 'project-index', params: { id: project.id } }"
            class="v-card--project"
          >
            <v-card-title class="pb-0">
              {{ project.name }}
            </v-card-title>
            <v-spacer />
            <v-card-text>
              <v-icon
                left
                color="firebase"
                v-text="'mdi-firebase'"
              />
              {{ project.firebaseConfig.projectId }}
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          v-for="project in availableFirebaseProjects"
          :key="project.projectId"
          cols="12"
          sm="6"
          md="4"
          lg="3"
          @click="getItem(project)"
        >
          <v-card
            outlined
            :loading="addingProject === project.projectId"
            flat
            min-height="10em"
            height="100%"
            class="v-card--project"
          >
            <v-card-title class="pb-0">
              {{ project.displayName }}
            </v-card-title>
            <v-card-text />
            <v-spacer />
            <v-card-text>
              <v-icon left v-text="'mdi-firebase'" />
              {{ project.projectId }}
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          v-if="requireReauth"
          cols="12"
          sm="6"
          md="4"
          lg="3"
        >
          <v-card
            outlined
            min-height="10em"
            height="100%"
            class="v-card--project align-center justify-center"
            @click="refreshAccessToken"
          >
            <v-avatar class="mt-n2">
              <v-icon large>
                mdi-plus
              </v-icon>
            </v-avatar>
            Fetch Available Projects
          </v-card>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
        >
          <v-card
            outlined
            min-height="10em"
            height="100%"
            :to="{ name: 'new-project' }"
            class="v-card--project align-center justify-center"
          >
            <v-avatar class="mt-n2">
              <v-icon large>
                mdi-plus
              </v-icon>
            </v-avatar>
            Add manually
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <router-view />
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import { db, Timestamp, auth, GoogleAuthProvider } from '@/plugins/firebase'
import { wait } from '@/utils/helpers'

export default {
  name: 'ProjectsIndex',
  data: () => ({
    availableProjects: [],
    addingProject: null,
    requireReauth: false
  }),
  computed: {
    ...mapState(['user', 'projects']),
    importedProjectIds() {
      return this.projects.map(proj => proj.firebaseConfig.projectId)
    },
    availableFirebaseProjects() {
      return this.availableProjects.filter(({ projectId, state }) => {
        return state === 'ACTIVE' && !this.importedProjectIds.includes(projectId)
      })
    }
  },
  async mounted() {
    const token = window.localStorage.getItem('accessToken')
    if (!token) {
      this.requireReauth = true
      return
    }
    try {
      const { data } = await axios.get(
        'https://firebase.googleapis.com/v1beta1/projects',
        { headers: { Authorization: `Bearer ${token}` } }
      )
      this.availableProjects = data.results || []
    } catch (error) {
      if (error.response?.data?.error?.code === 401) {
        this.requireReauth = true
        window.localStorage.removeItem('accessToken')
      }
    }
  },
  methods: {
    async refreshAccessToken() {
      const provider = new GoogleAuthProvider()
      provider.addScope('https://www.googleapis.com/auth/firebase')
      const { credential } = await auth.signInWithPopup(provider)
      credential.accessToken && window.localStorage.setItem('accessToken', credential.accessToken)
      window.location.reload()
    },
    async getItem(project) {
      const token = window.localStorage.getItem('accessToken')
      this.addingProject = project.projectId
      const { data } = await axios.get(
        `https://firebase.googleapis.com/v1beta1/${project.name}/webApps`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      const webApps = data?.apps || []
      const webApp = webApps.find(({ displayName }) => displayName === 'fireful') ?? await this.createWebApp(project)
      // console.log('selected', webApp.webId)
      // console.log('fetching config object')
      const { data: firebaseConfig } = await axios.get(
        `https://firebase.googleapis.com/v1beta1/${webApp.name}/config`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      // console.log('setting up the project')
      await db.collection('projects').doc(project.projectId).set({
        name: project.displayName,
        userEmails: [this.user.email],
        firebaseConfig,
        createdBy: this.user.uid,
        createdAt: Timestamp.fromDate(new Date()),
        updatedAt: Timestamp.fromDate(new Date())
      })
      this.$store.dispatch('snackbar/new', { message: 'Project created.' })
      this.$router.push({ name: 'project-index', params: { id: project.projectId } })
      this.addingProject = null
    },
    async createWebApp(project) {
      const token = window.localStorage.getItem('accessToken')
      // console.log('creating fireful webApp in your project')
      const { data } = await axios.post(
        `https://firebase.googleapis.com/v1beta1/${project.name}/webApps`,
        { displayName: 'fireful' },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      const operationName = data.name
      try {
        while (true) {
          await wait(1000)
          const { data: operationStatus } = await axios.get(
            `https://firebase.googleapis.com/v1beta1/${operationName}`,
            { headers: { Authorization: `Bearer ${token}` } }
          )
          if (operationStatus.done && operationStatus.response) {
            const { response } = operationStatus
            delete response['@type']
            return response
          }
        }
      } catch (error) {
        this.$store.dispatch('snackbar/new', { error })
      }
      return null
    }
  }
}
</script>

<style lang="sass">
.v-card.v-card--project
  border-radius: 8px
  border: 2px solid var(--v-primary-base)
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.2)
  display: flex
  flex-direction: column
</style>
